<template>
  <app-layout :title="title">
    <template #header>
      <div class="flex justify-between">
        <h2 class="text-2xl" v-html="title"></h2>

        <div class="flex gap-[40px] justify-between">
          <app-useful-links :items="usefulLinks" />
          <jet-danger-button
            type="link"
            :href="route(`${this.prefixRoute}.remove`, this.model.id)"
            >Remove</jet-danger-button
          >
        </div>
      </div>
    </template>

    <div class="mx-auto py-4">
      <div class="w-full">
        <nav class="border-b text-sm flex justify-start">
          <a
            :class="{
              'inline-block py-2 mr-4 text-slate-600 hover:text-slate-900': true,
              'border-b-2 border-orange-600 ': tab === 1,
            }"
            href="#"
            @click="currentTab(1)"
            >Data</a
          >
        </nav>
        <div class="pt-10">
          <div v-if="tab === 1">
            <form @submit.prevent="submit">
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="name" value="Name" />
                  <jet-input
                    id="name"
                    type="text"
                    class="block w-full mt-1"
                    v-model="form.name"
                    autofocus
                  />
                  <jet-input-error :message="form.errors.name" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="position" value="Position" />
                  <jet-input
                    id="position"
                    type="text"
                    class="block w-full mt-1"
                    v-model="form.position"
                    autofocus
                  />
                  <jet-input-error
                    :message="form.errors.position"
                    class="mt-2"
                  />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="url" value="Link" />
                  <jet-input
                    id="url"
                    type="text"
                    class="block w-full mt-1"
                    v-model="form.url"
                    autofocus
                  />
                  <jet-input-error :message="form.errors.url" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="icon" value="Icon" />
                  <jet-input
                    id="icon"
                    type="text"
                    class="block w-full mt-1"
                    v-model="form.icon"
                    autofocus
                  />
                  <jet-input-error :message="form.errors.icon" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="published" value="Published" />
                  <div class="mt-2 grid grid-cols-1 gap-4">
                    <label class="flex items-center">
                      <jet-checkbox
                        :value="form.published"
                        v-model:checked="form.published"
                      />
                      <span class="ml-2 text-sm text-slate-600">Yes</span>
                    </label>
                  </div>
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="cover" value="Cover" />

                  <image-editor
                    name="cover"
                    label="Cover"
                    desc="Ratio 1.5"
                    :value="form.cover"
                    :validation-rules="{
                      accept: ['image/png', 'image/jpeg', 'image/gif'],
                      maxSizeInKB: 100000,
                    }"
                    @update="onChangeUniversalNEW('cover', $event)"
                  ></image-editor>
                </div>
              </div>
              <div class="flex items-center justify-start pt-8">
                <jet-button
                  :class="{ 'opacity-25': form.processing }"
                  :disabled="form.processing"
                >
                  Save
                </jet-button>
                <jet-action-message :on="form.recentlySuccessful" class="ml-3">
                  Saved.
                </jet-action-message>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import { defineComponent, onMounted, onUpdated, ref, watch } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import AppLayout from '@/Layouts/AppLayout.vue';
import { useForm } from '@inertiajs/inertia-vue3';
import JetButton from '@/Jetstream/Button.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetDangerButton from '@/Jetstream/DangerButton.vue';
import JetActionMessage from '@/Jetstream/ActionMessage';
import JetTextarea from '@/Jetstream/Textarea';
import { MediaLibraryAttachment } from '../../../../../vendor_custom/laravel-medialibrary-pro/resources/js/media-library-pro-vue3-attachment';
import { MediaLibraryCollection } from '../../../../../vendor_custom/laravel-medialibrary-pro/resources/js/media-library-pro-vue3-collection';
import draggable from 'vuedraggable';
import JetDialogModal from '@/Jetstream/DialogModal';
import JetSecondaryButton from '@/Jetstream/SecondaryButton';
import VueTagsInput from '@sipec/vue3-tags-input';
import JetCheckbox from '@/Jetstream/Checkbox';
import VueEasyLightbox from 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js';
import { Inertia } from '@inertiajs/inertia';
import ImageEditor from '@/Components/ImageEditor.vue';
import AppUsefulLinks from '@/Components/usefulLinks.vue';

export default defineComponent({
  components: {
    ImageEditor,
    draggable,
    AppLayout,
    JetButton,
    JetFormSection,
    JetInput,
    JetInputError,
    JetLabel,
    JetDangerButton,
    JetActionMessage,
    JetTextarea,
    MediaLibraryAttachment,
    MediaLibraryCollection,
    JetDialogModal,
    JetSecondaryButton,
    Datepicker,
    VueTagsInput,
    JetCheckbox,
    VueEasyLightbox,
    AppUsefulLinks,
  },
  setup(props) {
    const tab = ref(1);
    const currentTab = (tabNumber) => (tab.value = tabNumber);
    const form = useForm({
      name: props.model.name,
      icon: props.model.icon,
      position: props.model.position,
      url: props.model.url,
      published: props.model.published,
      sort: props.model.sort,
      cover: props.model.cover,
    });
    onMounted(() => {});
    onUpdated(() => {});
    const enabled = ref(true);
    const dragging = ref(false);
    return {
      form,
      tab,
      currentTab,
    };
  },
  data() {
    return {
      validationErrors: this.$page.props.errors,
    };
  },
  props: {
    model: Object,
    prefixRoute: String,
    title: String,
    usefulLinks: Object,
  },
  methods: {
    submit() {
      this.form.put(route(`${this.prefixRoute}.update`, this.model.id));
    },
    onChangeUniversal(name, img) {
      this.form[name] = img;
      console.log(name, img);
    },
    onChangeUniversalNEW(name, img) {
      this.form[name] = img.value;
    },
  },
});
</script>

<style>
@import 'media-library-pro-styles';

.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}

.thumb {
  width: 4rem !important;
  height: 4rem !important;
  object-fit: cover !important;
}

.media-library-thumb {
  width: 10rem !important;
  height: 10rem !important;
  margin: 4rem;
}
</style>
