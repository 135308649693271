<template>




  <div
    class=""

  >
    <div
      v-if="!start"
      @click="start = true"
      class="w-full items-center px-4 py-2
      cursor-pointer
      bg-slate-50 border border-transparent rounded-md text-base text-slate-800 hover:bg-slate-300 focus:outline-none"
    >
      Sorting
    </div>

    <div v-else class="w-full">
      <div class="flex items-center justify-start gap-[20px]">
        <jet-secondary-button @click="column = 'one'">
          One column
        </jet-secondary-button>
        <jet-secondary-button @click="column = 'two'">
          Two column
        </jet-secondary-button>
        <jet-secondary-button @click="column = 'three'">
          Three column
        </jet-secondary-button>
        <jet-secondary-button @click="start = false" class="ml-auto">
          Close
        </jet-secondary-button>
      </div>

      <div class="mt-[20px]">
        <draggable
          :animation="300"
          ghost-class="!bg-green-50"
          group="items"
          :class="{
            'grid gap-[10px]': true,
            'grid-cols-1': column === 'one',
            'grid-cols-2': column === 'two',
            'grid-cols-3': column === 'three',
          }"
          :list="items"
          handle=".handle"
          itemKey="name"
        >
          <template #item="{ element, index }">
            <div
              class="handle bg-slate-100 px-[10px] py-[5px] cursor-move hover:bg-slate-50 flex flex-row gap-[20px]"
            >
              <img
                v-if="element.image"
                :src="element.image"
                class="h-[70px] w-[130px] object-cover border rounded-[4px]"
              />
              <div v-else class="w-[130px] h-[90px] bg-slate-200"></div>

              <div class="flex flex-col gap-[5px] justify-center">
                <div class="text-[20px] line-clamp-1" v-if="element.name">
                  {{ element.name.slice(0, 30) }}
                </div>
                <div class="line-clamp-1" v-if="element.descr">
                  {{ element.descr.slice(0, 30) }}

                  <template v-if="element.descr.lenght > 30">...</template>
                </div>
              </div>

              <div
                class="flex items-center px-[10px] text-3xl opacity-30 ml-auto"
              >
                {{ index + 1 }}
              </div>
            </div>
          </template>
        </draggable>
      </div>

      <div class="flex items-center justify-start pt-8">
        <jet-button @click="orderSave"> Save </jet-button>
        <jet-action-message :on="orderSaved" class="ml-3">
          Saved.
        </jet-action-message>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import JetButton from '@/Jetstream/Button.vue';
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue';

import JetActionMessage from '@/Jetstream/ActionMessage';
import draggable from 'vuedraggable';

const props = defineProps({
  items: Object,
  path: String,
});

const items = ref(null);
const orderSaved = ref(false);
const orderSave = function () {
  axios
    .post(props.path, {
      items: items.value,
    })
    .then((response) => {
      console.log(response.data);
      orderSaved.value = true;
      setTimeout(() => {
        orderSaved.value = false;
      }, 3000);
    })
    .catch((error) => {
      console.log(error);
    });
};

const start = ref(false);
const column = ref('two');

onMounted(() => {
  items.value = props.items;
});
</script>
