<template>
  <button :type="type" :class="classButton">
    <slot></slot>
  </button>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    type: {
      type: String,
      default: 'button',
    },
  },
  computed: {
    classButton() {
      let result = {
        'border border-1 disabled:opacity-25 focus:border-slate-700 focus:outline-none focus:ring focus:ring-slate-200 hover:bg-slate-100 hover:border-slate-600 inline-flex items-center justify-center px-4 py-2 rounded-md text-slate-500 text-base tracking-widest transition font-light': true,
      };

      if (typeof this.customClass !== 'undefined') {
        result[this.customClass] = true;
      }
      return result;
    },
  },
});
</script>
