<template>
  <a
    class="inline-block py-2 mr-4 text-slate-600 hover:text-slate-900 flex items-center border-b-2"
    :class="{
      'border-slate-900': isActive,
      'grayscale border-transparent': !isActive,
    }"
    href="#"
    @click="action"
  >
    <template v-if="isIcon">
      <span class="text-green-500 inline mr-1" v-if="iconName === 'success'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-5 h-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </span>

      <span class="text-yellow-600 inline mr-1" v-if="iconName === 'warning'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-5 h-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
          />
        </svg>
      </span>
    </template>

    {{ title }}
  </a>
</template>

<script setup>
const emit = defineEmits(['action']);
// const props = defineProps(['title', 'isActive'])
const props = defineProps({
  title: String,
  isActive: Boolean,
  isIcon: Boolean,
  iconName: String,
});
//
const action = function () {
  emit('action');
};
</script>
