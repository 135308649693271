<template>
  <app-layout :title="title">
    <template #header>
      <div class="flex justify-between">
        <h2 class="text-2xl" v-html="title"></h2>
        <div>
          <jet-danger-button
            type="link"
            :href="route(`${this.prefixRoute}.remove`, this.model.id)"
            >Remove
          </jet-danger-button>
        </div>
      </div>
    </template>

    <div class="mx-auto py-4">
      <div class="w-full">
        <nav class="border-b text-sm flex justify-start">
          <a
            :class="{
              'inline-block py-2 mr-4 text-slate-600 hover:text-slate-900': true,
              'border-b-2 border-orange-600 ': tab === 1,
            }"
            href="#"
            @click="currentTab(1)"
            >Data</a
          >
        </nav>
        <div class="pt-10">
          <div v-if="tab === 1">
            <form @submit.prevent="submit">
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="published" value="Published" />
                  <div class="mt-2 grid grid-cols-1 gap-4">
                    <label class="flex items-center">
                      <jet-checkbox
                        :value="form.published"
                        v-model:checked="form.published"
                      />
                      <span class="ml-2 text-sm text-slate-600">Yes</span>
                    </label>
                  </div>
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="name" value="Name" />
                  <jet-input
                    id="name"
                    type="text"
                    class="block w-full mt-1"
                    v-model="form.name"
                    autofocus
                  />
                  <jet-input-error :message="form.errors.name" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="url" value="URL" />
                  <jet-input
                    id="url"
                    type="text"
                    class="block w-full mt-1"
                    v-model="form.url"
                    autofocus
                  />
                  <jet-input-error :message="form.errors.url" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="desc" value="Desc" />
                  <jet-textarea
                    id="desc"
                    type="text"
                    class="block w-full mt-1"
                    v-model="form.desc"
                  />
                  <jet-input-error :message="form.errors.desc" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="order" value="Order" />
                  <jet-input
                    id="order"
                    type="text"
                    class="block w-full mt-1"
                    v-model="form.order"
                    autofocus
                  />
                  <jet-input-error :message="form.errors.order" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="tags" value="Tags" />

                  <draggable
                    :animation="300"
                    ghost-class="ghost-card"
                    group="items"
                    class="grid grid-cols-6 gap-1 mt-4"
                    :list="form.tags"
                    handle=".handle"
                    itemKey="name"
                  >
                    <template #item="{ element, index }">
                      <div
                        class="handle col-span-6 bg-green-50 text-base mt-2 border p-2 cursor-grabbing flex justify-between"
                      >
                        <span v-if="tags[element]">{{ tags[element] }}</span>
                      </div>
                    </template>
                  </draggable>
                </div>
              </div>

              <div class="flex items-center justify-start pt-8">
                <jet-button
                  :class="{ 'opacity-25': form.processing }"
                  :disabled="form.processing"
                >
                  Save
                </jet-button>
                <jet-action-message :on="form.recentlySuccessful" class="ml-3">
                  Saved.
                </jet-action-message>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script setup>
import { defineComponent, onMounted, onUpdated, ref } from 'vue';
import '@vuepic/vue-datepicker/dist/main.css';
import AppLayout from '@/Layouts/AppLayout.vue';
import { useForm } from '@inertiajs/inertia-vue3';
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetDangerButton from '@/Jetstream/DangerButton.vue';
import JetActionMessage from '@/Jetstream/ActionMessage';
import draggable from 'vuedraggable';
import JetCheckbox from '@/Jetstream/Checkbox';
import JetTextarea from '@/Jetstream/Textarea';

const props = defineProps({
  model: Object,
  tags: Object,
  prefixRoute: String,
  title: String,
});

const validationErrors = ref(null);
//

const tab = ref(1);
const currentTab = (tabNumber) => (tab.value = tabNumber);

const form = useForm({
  name: props.model.name,
  url: props.model.url,
  desc: props.model.desc,
  published: props.model.published,
  orderTags: props.model.orderTags,
  order: props.model.order,
  tags: props.model.tags,
});

const enabled = ref(true);
const dragging = ref(false);

const initTags = function () {
  console.log('asd');

  for (let tag in props.tags) {
    console.log(tag);
  }
};

onMounted(() => {
  initTags();

  validationErrors.value = this.$page.props.errors;
});

onUpdated(() => {});

const submit = function () {
  form.put(route(`${props.prefixRoute}.update`, props.model.id));
};

const onChangeUniversal = function (name, img) {
  form[name] = img;
  console.log(name, img);
};
</script>

<style>
@import 'media-library-pro-styles';

.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}

.thumb {
  width: 4rem !important;
  height: 4rem !important;
  object-fit: cover !important;
}

.media-library-thumb {
  width: 10rem !important;
  height: 10rem !important;
  margin: 4rem;
}
</style>
