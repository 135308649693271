<template>
  <div class="bg-gradient-to-r from-blue-300 to-blue-200">
    <div
      class="w-9/12 m-auto py-16 min-h-screen flex items-center justify-center"
    >
      <div class="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
        <div class="border-t border-slate-200 text-center pt-8">
          <h1 class="text-9xl text-blue-400">{{ status }}</h1>
          <h1 class="text-6xl font-medium py-8">{{ title }}</h1>
          <p class="text-2xl pb-8 px-12 font-medium">{{ description }}</p>
          <a
            href="/"
            class="bg-gradient-to-r from-blue-400 to-blue-500 hover:from-pink-500 hover:to-blue-500 text-white px-6 py-3 rounded-md mr-6"
          >
            Главная
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: Number,
  },
  computed: {
    title() {
      return {
        503: '503: Служба недоступна',
        500: '500: Ошибка сервера',
        404: '404: Страница не найдена',
        403: '403: Запрещено',
      }[this.status];
    },
    description() {
      return {
        503: 'Извините, мы проводим кое-какое техническое обслуживание.',
        500: 'Упс, что-то пошло не так на наших серверах.',
        404: 'Извините, страница, которую вы ищете, не найдена.',
        403: 'Извините, вам запрещен доступ к этой странице.',
      }[this.status];
    },
  },
};
</script>
