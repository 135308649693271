<template>
  <app-layout :title="title">
    <template #header>
      <div class="flex justify-between">
        <h2 class="text-2xl" v-html="title"></h2>
        <div class="flex gap-[40px] justify-between">
          <app-useful-links :items="usefulLinks" />
          <a :href="route(`${prefixRoute}.create`)">
            <jet-button>Create</jet-button>
          </a>
        </div>
      </div>
    </template>

    <div class="mx-auto py-4">
      <div class="flow-root">
        <div class="overflow-x-auto">
          <div class="inline-block min-w-full align-middle">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0"
                  >
                    <span class="sr-only">Edit</span>
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900"
                  >
                    Img
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900 hidden sm:table-cell"
                  >
                    Tags
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900 hidden sm:table-cell"
                  >
                    <div class="flex gap-[20px]">
                      <div>Min {{ minItemsCount }}</div>
                      <div>Max {{ maxItemsCount }}</div>
                      <div v-if="0">Avg {{ averageItemsCount }}</div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr
                  v-for="item in modelList"
                  :key="item.id"
                  :class="{ 'opacity-50': !item.published }"
                >
                  <td
                    class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-slate-900 sm:pl-0 w-[60px]"
                  >
                    <a
                      :href="route(`${prefixRoute}.show`, item.id)"
                      class="bg-slate-50 text-slate-500 inline-flex hover:bg-slate-200 hover:text-slate-800 items-center justify-end p-3 rounded"
                    >
                      <PencilIcon class="h-5 w-5" aria-hidden="true" />
                    </a>
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm">
                    <template v-if="item.work_cover_large_type === 'image'">
                      <img
                        v-if="item.work_cover_large"
                        :src="item.work_cover_large"
                        alt="Cover Image"
                        class="h-[70px] w-[130px] object-cover border rounded-[4px]"
                      />
                    </template>
                    <template
                      v-else-if="item.work_cover_large_type === 'vimeo'"
                    >
                      <div
                        class="h-[70px] w-[130px] flex items-center justify-center border border-slate-200 rounded bg-slate-100 text-slate-500"
                      >
                        vimeo
                      </div>
                    </template>
                    <template
                      v-else-if="item.work_cover_large_type === 'video'"
                    >
                      <div
                        class="h-[70px] w-[130px] flex items-center justify-center border border-slate-200 rounded bg-slate-100 text-slate-500"
                      >
                        video
                      </div>
                    </template>
                    <template
                      v-else-if="item.work_cover_large_type === 'empty'"
                    >
                      <div
                        class="h-[70px] w-[130px] flex items-center justify-center border border-slate-200 rounded bg-slate-100 text-slate-500"
                      >
                        empty
                      </div>
                    </template>
                    <template v-else>
                      <div
                        class="h-[70px] w-[130px] flex items-center justify-center border border-slate-200 rounded bg-slate-100 text-slate-500"
                      >
                        unknown
                      </div>
                    </template>
                  </td>

                  <td
                    class="whitespace-nowrap px-2 py-2 text-sm text-slate-900"
                  >
                    <div class="truncate max-w-[200px]" :title="item.name">
                      {{ item.name }}
                    </div>
                  </td>
                  <td
                    class="whitespace-nowrap px-2 py-2 text-sm text-slate-500 hidden sm:table-cell"
                  >
                    <div v-if="item.tagsList" class="flex flex-wrap gap-[15px] max-w-[350px]">
                      <span
                        v-for="tag in item.tagsList"
                        :key="tag"
                        class="inline-block bg-slate-100 text-slate-800 px-2 py-1 rounded mr-1"
                        >{{ tag }}</span
                      >
                    </div>
                  </td>
                  <td
                    class="whitespace-nowrap px-2 py-2 text-sm text-slate-900 hidden sm:table-cell"
                  >
                    <ProgressBar :max="50" :value="item.items_count" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <app-sort-card :items="sortItems" :path="sortItemsURL" />
  </app-layout>
</template>

<script setup>
import { computed } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import AppSortCard from '@/Components/Sort/SortCard.vue';
import ProgressBar from '@/Components/ProgressBar.vue';
import AppUsefulLinks from '@/Components/usefulLinks.vue';
import PencilIcon from '@heroicons/vue/outline/PencilIcon';

const props = defineProps({
  modelList: Object,
  prefixRoute: String,
  title: String,
  sortItems: Object,
  sortItemsURL: String,
  usefulLinks: Object,
});

const averageItemsCount = computed(() => {
  const publishedItems = props.modelList.filter((item) => item.published);
  if (publishedItems.length === 0) {
    return 0;
  }
  const totalItemsCount = publishedItems.reduce(
    (sum, item) => sum + item.items_count,
    0
  );
  return Math.floor(totalItemsCount / publishedItems.length);
});

const minItemsCount = computed(() => {
  const publishedItems = props.modelList.filter((item) => item.published);
  if (publishedItems.length === 0) {
    return 0;
  }
  return Math.min(...publishedItems.map((item) => item.items_count));
});

const maxItemsCount = computed(() => {
  const publishedItems = props.modelList.filter((item) => item.published);
  if (publishedItems.length === 0) {
    return 0;
  }
  return Math.max(...publishedItems.map((item) => item.items_count));
});
</script>
