<template>
  <app-layout :title="title">
    <template #header>
      <div class="flex justify-between">
        <h2 class="text-2xl" v-html="title"></h2>
        <div></div>
      </div>
    </template>

    <div class="mx-auto py-4">
      <div class="w-full">
        <nav class="border-b text-sm flex justify-start">
          <a
            :class="{
              'inline-block py-2 mr-4 text-slate-600 hover:text-slate-900': true,
              'border-b-2 border-orange-600 ': tab === 1,
            }"
            href="#"
            @click="currentTab(1)"
            >Data</a
          >
        </nav>
        <div class="pt-10">
          <div v-if="tab === 1">
            <form @submit.prevent="submit">
              <div class="grid grid-cols-6 gap-6">
                <!--





								-->

                <div class="col-span-6 sm:col-span-4 grid grid-cols-6 gap-6">
                  <div class="col-span-1" v-if="model.code">
                    {{ model.code }}
                  </div>

                  <div class="col-span-3" v-if="model.full_name">
                    {{ model.full_name }}
                  </div>

                  <div class="col-span-2">
                    <img
                      v-if="model.image"
                      :src="`https://works.shuka.design/${model.image}`"
                      class="w-48 object-contain"
                      alt=""
                    />
                  </div>
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="name" value="Name" />
                  <jet-input
                    id="name"
                    type="text"
                    class="block w-full mt-1"
                    v-model="form.name"
                    autofocus
                  />
                  <jet-input-error :message="form.errors.name" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="link" value="Link" />
                  <jet-input
                    id="link"
                    type="text"
                    class="block w-full mt-1"
                    v-model="form.link"
                    autofocus
                  />
                  <jet-input-error :message="form.errors.link" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="published" value="Published" />
                  <div class="mt-2 grid grid-cols-1 gap-4">
                    <label class="flex items-center">
                      <jet-checkbox
                        :value="form.published"
                        v-model:checked="form.published"
                      />
                      <span class="ml-2 text-sm text-slate-600">Yes</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="flex items-center justify-start pt-8">
                <jet-button
                  :class="{ 'opacity-25': form.processing }"
                  :disabled="form.processing"
                >
                  Save
                </jet-button>
                <jet-action-message :on="form.recentlySuccessful" class="ml-3">
                  Saved.
                </jet-action-message>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import { defineComponent, onMounted, onUpdated, ref } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import AppLayout from '@/Layouts/AppLayout.vue';
import { useForm } from '@inertiajs/inertia-vue3';
import JetButton from '@/Jetstream/Button.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetDangerButton from '@/Jetstream/DangerButton.vue';
import JetActionMessage from '@/Jetstream/ActionMessage';
import JetTextarea from '@/Jetstream/Textarea';
import { MediaLibraryAttachment } from '../../../../../vendor_custom/laravel-medialibrary-pro/resources/js/media-library-pro-vue3-attachment';
import { MediaLibraryCollection } from '../../../../../vendor_custom/laravel-medialibrary-pro/resources/js/media-library-pro-vue3-collection';
import draggable from 'vuedraggable';
import JetDialogModal from '@/Jetstream/DialogModal';
import JetSecondaryButton from '@/Jetstream/SecondaryButton';
import VueTagsInput from '@sipec/vue3-tags-input';
import JetCheckbox from '@/Jetstream/Checkbox';
import VueEasyLightbox from 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js';

export default defineComponent({
  components: {
    draggable,
    AppLayout,
    JetButton,
    JetFormSection,
    JetInput,
    JetInputError,
    JetLabel,
    JetDangerButton,
    JetActionMessage,
    JetTextarea,
    MediaLibraryAttachment,
    MediaLibraryCollection,
    JetDialogModal,
    JetSecondaryButton,
    Datepicker,
    VueTagsInput,
    JetCheckbox,
    VueEasyLightbox,
  },
  setup(props) {
    const tab = ref(1);
    const currentTab = (tabNumber) => (tab.value = tabNumber);
    const form = useForm({
      name: props.model.name,
      link: props.model.link,
      published: props.model.published,
    });
    onMounted(() => {});
    onUpdated(() => {});
    return {
      form,
      tab,
      currentTab,
    };
  },
  data() {
    return {
      validationErrors: this.$page.props.errors,
    };
  },
  props: {
    model: Object,
    prefixRoute: String,
    title: String,
  },
  methods: {
    submit() {
      this.form.put(route(`${this.prefixRoute}.update`, this.model.id));
    },
    onChangeUniversal(name, img) {
      this.form[name] = img;
      console.log(name, img);
    },
  },
});
</script>
