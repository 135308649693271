<template>
  <div class="md:grid md:grid-cols-3 md:gap-6">
    <jet-section-title>
      <template #title>
        <slot name="title"></slot>
      </template>
      <template #description>
        <slot name="description"></slot>
      </template>
    </jet-section-title>

    <div class="mt-5 md:mt-0 md:col-span-2">
      <form @submit.prevent="$emit('submitted')">
        <div
          class="px-4 py-5 bg-white sm:p-6 shadow"
          :class="
            hasActions ? 'sm:rounded-tl-md sm:rounded-tr-md' : 'sm:rounded-md'
          "
        >
          <div class="grid grid-cols-6 gap-6">
            <slot name="form"></slot>
          </div>
        </div>

        <div class="flex items-center justify-start pt-8" v-if="hasActions">
          <slot name="actions"></slot>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import JetSectionTitle from './SectionTitle.vue';

export default defineComponent({
  emits: ['submitted'],
  components: {
    JetSectionTitle,
  },
  computed: {
    hasActions() {
      return !!this.$slots.actions;
    },
  },
});
</script>
