<template>
  <label
    class="block font-medium text-sm"
    :class="{
      'text-slate-600': !error,
      'text-red-600': error,
    }"
  >
    <span v-if="value"
      >{{ value }} <span v-if="require" class="text-red-500">*</span></span
    >
    <span v-else><slot></slot></span>
  </label>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: ['value', 'require', 'error'],
});
</script>
