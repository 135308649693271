<template>
  <Head title="Welcome" />

  <div
    class="relative flex items-top justify-center min-h-screen bg-slate-100 dark:bg-slate-900 sm:items-center sm:pt-0"
  >
    <div v-if="canLogin" class="hidden fixed top-0 right-0 px-6 py-4 sm:block">
      <Link
        v-if="$page.props.user"
        :href="route('admin::dashboard')"
        class="text-sm text-slate-600 underline"
      >
        Управление 1
      </Link>
    </div>

    <div class="mx-auto">
      <div class="flex justify-center pt-8 sm:justify-start sm:pt-0">
        <img src="../../img/logo.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Head, Link } from '@inertiajs/inertia-vue3';

export default defineComponent({
  components: {
    Head,
    Link,
  },
  props: {
    canLogin: Boolean,
    canRegister: Boolean,
    laravelVersion: String,
    phpVersion: String,
  },
});
</script>
