<template>
  <app-layout :title="title">
    <template #header>
      <div class="flex justify-between">
        <h2 class="text-2xl">{{ title }}</h2>
        <div>
          <a :href="route(`${prefixRoute}.create`)">
            <jet-button>Create</jet-button>
          </a>
        </div>
      </div>
    </template>

    <div class="mx-auto py-4">
      <Table :resource="modelList">
        <template v-slot:tableWrapper="slotProps">
          <div class="flex flex-col mt-4">
            <div
              class="mb-6 shadow overflow-hidden border-b border-slate-200 sm:rounded-lg"
            >
              <table class="min-w-full divide-y divide-slate-200">
                <thead class="bg-slate-200">
                  <tr>
                    <th
                      class="px-6 py-3 text-left text-base font-normal tracking-wider cursor-pointer"
                      @click.prevent="sortBy('id')"
                    >
                      ID
                    </th>
                    <th
                      class="px-6 py-3 text-left text-base font-normal tracking-wider cursor-pointer"
                      @click.prevent="sortBy('name')"
                    >
                      Name
                    </th>
                    <th
                      class="px-6 py-3 text-left text-base font-normal tracking-wider cursor-pointer"
                      @click.prevent="sortBy('code')"
                    >
                      Code
                    </th>
                    <th
                      class="px-6 py-3 text-right text-base font-normal tracking-wider"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-slate-200">
                  <tr
                    v-for="(item, key, index) in modelList.data"
                    :key="item.id"
                  >
                    <td class="px-6 py-4 whitespace-nowrap">{{ item.id }}</td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <template v-if="item.name"
                        >{{ index }} {{ item.name.slice(0, 25)
                        }}<span v-if="item.name.length > 25"
                          >...</span
                        ></template
                      >
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <template v-if="item.url"
                        >{{ item.url.slice(0, 25)
                        }}<span v-if="item.url.length > 25">...</span></template
                      >
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-right">
                      <a
                        :href="route(`${prefixRoute}.show`, item.id)"
                        :title="item.id"
                        class="relative inline-flex items-center px-3 py-2 rounded-md border border-slate-300 bg-white text-sm font-medium text-slate-700 hover:bg-slate-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-orange-500 focus:border-orange-500"
                      >
                        <PencilIcon
                          class="h-5 w-5 text-slate-400"
                          aria-hidden="true"
                        />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Pagination :meta="modelList.meta" />
          </div>
        </template>

        <template v-slot:tableGlobalSearch="slotProps">
          <div class="flex-grow">
            <div class="relative">
              <input
                class="block w-full sm:text-sm rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 border border-slate-300 px-4 py-2 outline-none"
                placeholder="Search..."
                :value="slotProps.search.global.value"
                type="text"
                @input="slotProps.changeGlobalSearchValue($event.target.value)"
              />
              <div
                class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-slate-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </div>
        </template>
      </Table>
    </div>
  </app-layout>
</template>

<script setup>
import AppLayout from '@/Layouts/AppLayout.vue';
import {
  Table,
  Pagination,
} from '@protonemedia/inertiajs-tables-laravel-query-builder';
import PencilIcon from '@heroicons/vue/outline/PencilIcon';
import JetButton from '@/Jetstream/Button.vue';

const props = defineProps({
  modelList: Object,
  prefixRoute: String,
  title: String,
});
</script>
