<template>
  <app-layout :title="title">
    <template #header>
      <div class="flex justify-between">
        <h2 class="text-2xl" v-html="title"></h2>
      </div>
    </template>

    <div class="mx-auto py-4">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="mt-5 md:mt-0 md:col-span-2">
          <form @submit.prevent="submit">
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6 sm:col-span-4">
                <jet-label for="name" value="Name" />
                <jet-input
                  id="name"
                  type="text"
                  class="block w-full mt-1"
                  v-model="form.name"
                  autofocus
                />
                <jet-input-error :message="form.errors.name" class="mt-2" />
              </div>
            </div>
            <div class="flex items-center justify-start pt-8">
              <jet-button
                :class="{ 'opacity-25': form.processing }"
                :disabled="form.processing"
              >
                Create
              </jet-button>
              <jet-action-message :on="form.recentlySuccessful" class="ml-3">
                Created.
              </jet-action-message>
            </div>
          </form>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import { useForm } from '@inertiajs/inertia-vue3';
import JetButton from '@/Jetstream/Button.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetDangerButton from '@/Jetstream/DangerButton.vue';
import JetActionMessage from '@/Jetstream/ActionMessage';
import JetTextarea from '@/Jetstream/Textarea';

export default defineComponent({
  components: {
    AppLayout,
    JetButton,
    JetFormSection,
    JetInput,
    JetInputError,
    JetLabel,
    JetDangerButton,
    JetActionMessage,
    JetTextarea,
  },
  setup(props) {
    const form = useForm({
      name: null,
    });
    return { form };
  },
  props: {
    model: Object,
    prefixRoute: String,
    title: String,
    titleSection: String,
    descSection: String,
  },
  methods: {
    submit() {
      this.form.post(route(`${this.prefixRoute}.store`));
    },
  },
});
</script>
