<template>
  <modal
    :show="show"
    :max-width="maxWidth"
    :closeable="closeable"
    @close="close"
  >
    <div class="">
      <!--			<div class="text-lg">-->
      <!--				<slot name="title">-->
      <!--				</slot>-->
      <!--			</div>-->

      <div class="">
        <iframe :src="url" :style="styleIframe" class="w-full"></iframe>

        <slot name="content"> </slot>
      </div>
    </div>

    <div class="px-6 py-4 bg-slate-100 text-right">
      <slot name="footer"> </slot>
    </div>
  </modal>
</template>

<script>
import { defineComponent } from 'vue';
import Modal from './../Modal.vue';

export default defineComponent({
  emits: ['close'],
  components: {
    Modal,
  },
  props: {
    iframeUrl: {
      type: String,
    },
    show: {
      default: false,
    },
    maxWidth: {
      default: '7xl',
    },
    closeable: {
      default: true,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
  computed: {
    url() {
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      return this.iframeUrl + '?height=' + (height - 270);
    },

    styleIframe() {
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      return {
        height: height - 200 + 'px',
      };
    },
  },
});
</script>
