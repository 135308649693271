<template>
  <a
    v-if="type === 'link'"
    :href="href"
    :target="target"
    :class="[classButton]"
  >
    <slot></slot>
  </a>
  <button v-else :type="type" :class="classButton">
    <slot></slot>
  </button>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    type: {
      type: String,
      default: 'submit',
    },
    href: {
      type: String,
    },
    target: {
      type: String,
      default: '_self',
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    classButton() {
      let result = {
        'inline-flex items-center px-4 py-2 bg-slate-800 border border-transparent rounded-md text-base text-white': true,
        'tracking-widest hover:bg-slate-700 active:bg-slate-900 focus:outline-none focus:border-slate-900': true,
        'focus:ring focus:ring-slate-300 disabled:opacity-25 transition': true,
        'font-light': true,
      };
      if (typeof this.customClass !== 'undefined') {
        result[this.customClass] = true;
      }
      return result;
    },
  },
});
</script>
