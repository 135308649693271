<template>
  <div
    class="card border border-slate-200 rounded- transform transition-all duration-200"
  >
    <div class="m-3">
      <h2 class="text-sm mb-2" v-if="media">
        {{ label }}

        <a
          :href="media.original_url"
          target="_blank"
          class="text-sm text-teal-800 bg-teal-100 inline rounded-full px-2 align-top float-right animate-pulse"
        >
          download
        </a>
      </h2>

      <div class="flex flex-col gap-2">
        <media-library-attachment
          name="original"
          :initial-value="value"
          :validation-errors="validationErrors"
          :validation-rules="validationRules"
          @change="onChange"
        >
          ></media-library-attachment
        >

        <div class="text-sm">{{ desc }}</div>

        <div v-if="media">
          <template v-if="media.mime_type === 'video/mp4'">
            <video
              :src="media.original_url"
              class="w-[300px] h-[200px]"
              muted
              controls
            ></video>
          </template>

          <table class="border-spacing-2 w-full text-sm text-slate-400">
            <tbody>
              <tr>
                <td>MIME type</td>
                <td>{{ media.mime_type }}</td>
              </tr>
              <tr v-if="media.custom_properties.width">
                <td>Width</td>
                <td>{{ media.custom_properties.width }}</td>
              </tr>
              <tr v-if="media.custom_properties.height">
                <td>Height</td>
                <td>{{ media.custom_properties.height }}</td>
              </tr>
              <tr v-if="media.custom_properties.ratio">
                <td>Ratio</td>
                <td>{{ media.custom_properties.ratio }}</td>
              </tr>
              <tr v-if="media.custom_properties.hex">
                <td>hex</td>
                <td>{{ media.custom_properties.hex }}</td>
              </tr>

              <tr>
                <td>
                  <div class="flex item-start">Resp</div>
                </td>
                <td>
                  <div class="flex gap-[20px]">
                    <div
                      v-if="respWebp && !isVisibleResp"
                      @click="isVisibleResp = true"
                      class="cursor-pointer"
                    >
                      <CheckCircleIcon
                        class="text-green-500 w-[25px] h-[25px]"
                      ></CheckCircleIcon>
                    </div>

                    <div
                      v-else
                      @click="isVisibleResp = false"
                      class="cursor-pointer"
                    >
                      <XCircleIcon
                        class="bg-slate-900 w-[25px] h-[25px]"
                      ></XCircleIcon>
                    </div>

                    <div
                      v-if="respWebp && isVisibleResp"
                      class="flex flex-wrap gap-[5px]"
                    >
                      <a
                        :href="url.link"
                        v-for="url in respWebp"
                        target="_blank"
                        class="px-[4px] py-[2px] rounded-[4px] border-slate border border-solid flex-none hover:bg-slate-900 hover:border-black hover:text-white"
                      >
                        {{ url.w }} x {{ url.h }}
                      </a>
                    </div>

                    <div v-if="respWebp.lenght === 0">
                      <XCircleIcon
                        class="text-red-500 w-[25px] h-[25px]"
                      ></XCircleIcon>
                    </div>
                  </div>
                </td>
              </tr>

              <tr v-if="media.mime_type === 'video/mp4'">
                <td>
                  <div class="flex item-start">Super</div>
                </td>
                <td>
                  <div class="flex flex-wrap gap-[20px]">
                    <div class="">
                      <div>Crop & Replace video</div>
                      <div class="flex flex-wrap gap-[10px]">
                        <div
                          @click="clickerCropActions(item.link)"
                          v-for="item in cropActions"
                          target="_blank"
                          class="px-[4px] py-[2px] rounded-[4px] border-slate border border-solid flex-none hover:bg-slate-900 hover:border-black hover:text-white cursor-pointer"
                        >
                          {{ item.name }}
                          <template v-if="item.counter"
                            >...{{ item.counter }}</template
                          >
                        </div>
                      </div>
                    </div>

                    <div class="">
                      <div>Clear</div>
                      <div class="flex flex-wrap gap-[10px]">
                        <div
                          @click="clickerClearActions(item.link)"
                          v-for="item in clearActions"
                          target="_blank"
                          class="px-[4px] py-[2px] rounded-[4px] border-slate border border-solid flex-none hover:bg-slate-900 hover:border-black hover:text-white cursor-pointer"
                        >
                          {{ item.name }}
                          <template v-if="item.counter"
                            >({{ item.counter }})</template
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { MediaLibraryAttachment } from 'media-library-pro-vue3-attachment';
import JetCheckboxItem from '@/Jetstream/CheckboxItem';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/outline';

const emit = defineEmits(['update']);

const props = defineProps({
  name: String,
  label: String,
  desc: String,
  value: Object,
  validationErrors: Object,
  validationRules: Object,
});

const media = ref(null);

const onChange = function (e) {
  console.log({
    name: props.name,
    value: [e],
  });

  emit('update', {
    name: props.name,
    value: e,
  });
};

console.log('good', props.value);

const respWebp = computed(() => {
  let result = [];

  if (
    media.value.responsive_images &&
    typeof media.value.responsive_images['resp-webp'] !== 'undefined'
  ) {
    for (let i in media.value.responsive_images['resp-webp'].urls) {
      let url = media.value.responsive_images['resp-webp'].urls[i];

      let words = url.split('resp-webp');
      words = words[1].split('_');

      result.push({
        w: words[1].replace(/[^0-9]/g, ''),
        h: words[2].replace(/[^0-9]/g, ''),
        link: `https://cdn.shuka.design/${media.value.id}/responsive-images/${url}`,
      });
    }
  }

  return result;
});

const cropActions = ref([]);
const clearActions = ref([]);

onMounted(() => {
  if (props.value !== null && typeof props.value[0] !== 'undefined') {
    media.value = props.value[0];

    cropActions.value.push({
      name: '9/16',
      link: route('admin::crop-video-media', {
        media: media.value.id,
        size: '1080x1920',
      }),
    });

    cropActions.value.push({
      name: '16/9',
      link: route('admin::crop-video-media', {
        media: media.value.id,
        size: '1920x1080',
      }),
    });

    cropActions.value.push({
      name: '9/9',
      link: route('admin::crop-video-media', {
        media: media.value.id,
        size: '1080x1080',
      }),
    });

    //

    clearActions.value.push({
      name: 'Remove audio',
      link: route('admin::remove-audio-video-media', { media: media.value.id }),
    });
  }
});

const isVisibleResp = ref(false);

const clickerCropActions = function (link) {
  let index = cropActions.value.findIndex(function (itemAction) {
    return itemAction.link === link;
  });

  if (typeof cropActions.value[index].counter !== 'undefined') {
    cropActions.value[index].counter--;
  } else {
    cropActions.value[index].counter = 20;
  }

  if (cropActions.value[index].counter === 0) {
    startAction(link);
  }
};

const startAction = function (link) {
  console.log('action', link, media.value);

  axios.get(link).then((response) => {
    console.log(response.data);
  });
};

const clickerClearActions = function (link) {
  let index = clearActions.value.findIndex(function (itemAction) {
    return itemAction.link === link;
  });

  if (typeof clearActions.value[index].counter !== 'undefined') {
    clearActions.value[index].counter--;
  } else {
    clearActions.value[index].counter = 20;
  }

  if (clearActions.value[index].counter === 0) {
    startAction(link);
  }
};
</script>
