<template>
  <div class="bg-sky-100 h-[3px] relative rounded-[2px] w-full">
    <div class="-top-[20px] absolute left-0 text-slate-300">{{ value }}</div>

    <div
      class="h-[3px] bg-gradient-to-r"
      :class="gradientClass"
      :style="{ width: `${progressWidth}%` }"
    ></div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  max: {
    type: Number,
    required: true,
  },
  value: {
    type: Number,
    required: true,
  },
});

const progressWidth = computed(() => {
  if (props.max <= 0) return 0;
  return Math.min((props.value / props.max) * 100, 100);
});

const gradientClass = computed(() => {
  const percentage = progressWidth.value;
  return 'from-sky-200 to-sky-400';
});
</script>
