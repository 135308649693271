<template>
  <jet-action-section>
    <template #title> Remove аккаунт </template>

    <template #description> Remove вашу учетную запись навсегда </template>

    <template #content>
      <div class="max-w-xl text-sm text-slate-600">
        После удаления вашей учетной записи все ее ресурсы и данные будут
        удалены без возможности восстановления. Перед удалением учетной записи
        загрузите любые данные или информацию, которые вы хотите сохранить.
      </div>

      <div class="mt-5">
        <jet-danger-button @click="confirmUserDeletion">
          Remove аккаунт
        </jet-danger-button>
      </div>

      <jet-dialog-modal :show="confirmingUserDeletion" @close="closeModal">
        <template #title> Remove аккаунт </template>

        <template #content>
          Вы уверены, что хотите удалить свою учетную запись? После удаления
          вашей учетной записи все ее ресурсы и данные будут удалены без
          возможности восстановления. Пожалуйста, введите свой пароль, чтобы
          подтвердить, что вы хотите навсегда удалить свою учетную запись.

          <div class="mt-4">
            <jet-input
              type="password"
              class="mt-1 block w-3/4"
              placeholder="Password"
              ref="password"
              v-model="form.password"
              @keyup.enter="deleteUser"
            />

            <jet-input-error :message="form.errors.password" class="mt-2" />
          </div>
        </template>

        <template #footer>
          <jet-secondary-button @click="closeModal">
            Отмена
          </jet-secondary-button>

          <jet-danger-button
            class="ml-2"
            @click="deleteUser"
            :class="{ 'opacity-25': form.processing }"
            :disabled="form.processing"
          >
            Remove аккаунт
          </jet-danger-button>
        </template>
      </jet-dialog-modal>
    </template>
  </jet-action-section>
</template>

<script>
import { defineComponent } from 'vue';
import JetActionSection from '@/Jetstream/ActionSection.vue';
import JetDialogModal from '@/Jetstream/DialogModal.vue';
import JetDangerButton from '@/Jetstream/DangerButton.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue';

export default defineComponent({
  components: {
    JetActionSection,
    JetDangerButton,
    JetDialogModal,
    JetInput,
    JetInputError,
    JetSecondaryButton,
  },
  data() {
    return {
      confirmingUserDeletion: false,
      form: this.$inertia.form({
        password: '',
      }),
    };
  },
  methods: {
    confirmUserDeletion() {
      this.confirmingUserDeletion = true;
      setTimeout(() => this.$refs.password.focus(), 250);
    },
    deleteUser() {
      this.form.delete(route('current-user.destroy'), {
        preserveScroll: true,
        onSuccess: () => this.closeModal(),
        onError: () => this.$refs.password.focus(),
        onFinish: () => this.form.reset(),
      });
    },
    closeModal() {
      this.confirmingUserDeletion = false;
      this.form.reset();
    },
  },
});
</script>
