<template>
  <div>
    <!-- Generate API Token -->
    <jet-form-section @submitted="createApiToken">
      <template #title> Create API-токен </template>

      <template #description>
        <!--				API tokens allow third-party services to authenticate with our application on your behalf.-->
        API-токены позволяют сторонним службам авторизоваться в нашем приложении
        от вашего имени.
      </template>

      <template #form>
        <!-- Token Name -->
        <div class="col-span-6 sm:col-span-4">
          <jet-label for="name" value="Имя" />
          <jet-input
            id="name"
            type="text"
            class="mt-1 block w-full"
            v-model="createApiTokenForm.name"
            autofocus
          />
          <jet-input-error
            :message="createApiTokenForm.errors.name"
            class="mt-2"
          />
        </div>

        <!-- Token Permissions -->
        <div class="col-span-6" v-if="availablePermissions.length > 0">
          <jet-label for="permissions" value="Permissions" />

          <div class="mt-2 grid grid-cols-1 md:grid-cols-2 gap-4">
            <div v-for="permission in availablePermissions" :key="permission">
              <label class="flex items-center">
                <jet-checkbox
                  :value="permission"
                  v-model:checked="createApiTokenForm.permissions"
                />
                <span class="ml-2 text-sm text-slate-600">{{
                  permission
                }}</span>
              </label>
            </div>
          </div>
        </div>
      </template>

      <template #actions>
        <jet-action-message
          :on="createApiTokenForm.recentlySuccessful"
          class="mr-3"
        >
          Создано
        </jet-action-message>

        <jet-button
          :class="{ 'opacity-25': createApiTokenForm.processing }"
          :disabled="createApiTokenForm.processing"
        >
          Создание
        </jet-button>
      </template>
    </jet-form-section>

    <div v-if="tokens.length > 0">
      <jet-section-border />

      <!-- Manage API Tokens -->
      <div class="mt-10 sm:mt-0">
        <jet-action-section>
          <template #title> Управление API-токенами </template>

          <template #description>
            <!--						You may delete any of your existing tokens if they are no longer needed.-->
            Вы можете удалить любой из имеющихся у вас токенов, если он больше
            не нужен.
          </template>

          <!-- API Token List -->
          <template #content>
            <div class="space-y-6">
              <div
                class="flex items-center justify-between"
                v-for="token in tokens"
                :key="token.id"
              >
                <div>
                  {{ token.name }}
                </div>

                <div class="flex items-center">
                  <div
                    class="text-sm text-slate-400"
                    v-if="token.last_used_ago"
                  >
                    Last used {{ token.last_used_ago }}
                  </div>

                  <button
                    class="cursor-pointer ml-6 text-sm text-slate-400 underline"
                    @click="manageApiTokenPermissions(token)"
                    v-if="availablePermissions.length > 0"
                  >
                    Права доступа
                  </button>

                  <button
                    class="cursor-pointer ml-6 text-sm text-red-500"
                    @click="confirmApiTokenDeletion(token)"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </template>
        </jet-action-section>
      </div>
    </div>

    <!-- Token Value Modal -->
    <jet-dialog-modal :show="displayingToken" @close="displayingToken = false">
      <template #title> API-токен </template>

      <template #content>
        <div>
          <!--					Please copy your new API token. For your security, it won't be shown again.-->
          Скопируйте свой новый API-токен. В целях безопасности он больше не
          будет отображаться.
        </div>

        <div
          class="mt-4 bg-slate-100 px-4 py-2 rounded font-mono text-sm text-slate-600"
          v-if="$page.props.jetstream.flash.token"
        >
          {{ $page.props.jetstream.flash.token }}
        </div>
      </template>

      <template #footer>
        <jet-secondary-button @click="displayingToken = false">
          Close
        </jet-secondary-button>
      </template>
    </jet-dialog-modal>

    <!-- API Token Permissions Modal -->
    <jet-dialog-modal
      :show="managingPermissionsFor"
      @close="managingPermissionsFor = null"
    >
      <template #title>
        <!--				API Token Permissions-->
        API-токен. Права доступа.
      </template>

      <template #content>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div v-for="permission in availablePermissions" :key="permission">
            <label class="flex items-center">
              <jet-checkbox
                :value="permission"
                v-model:checked="updateApiTokenForm.permissions"
              />
              <span class="ml-2 text-sm text-slate-600">{{ permission }}</span>
            </label>
          </div>
        </div>
      </template>

      <template #footer>
        <jet-secondary-button @click="managingPermissionsFor = null">
          Выйти
        </jet-secondary-button>

        <jet-button
          class="ml-2"
          @click="updateApiToken"
          :class="{ 'opacity-25': updateApiTokenForm.processing }"
          :disabled="updateApiTokenForm.processing"
        >
          Save
        </jet-button>
      </template>
    </jet-dialog-modal>

    <!-- Delete Token Confirmation Modal -->
    <jet-confirmation-modal
      :show="apiTokenBeingDeleted"
      @close="apiTokenBeingDeleted = null"
    >
      <template #title>
        <!--				Delete API Token-->
        Remove API-токен
      </template>

      <template #content>
        <!--				Are you sure you would like to delete this API token?-->
        Вы уверены, что хотите удалить этот API-токен?
      </template>

      <template #footer>
        <jet-secondary-button @click="apiTokenBeingDeleted = null">
          Выйти
        </jet-secondary-button>

        <jet-danger-button
          class="ml-2"
          @click="deleteApiToken"
          :class="{ 'opacity-25': deleteApiTokenForm.processing }"
          :disabled="deleteApiTokenForm.processing"
        >
          Remove
        </jet-danger-button>
      </template>
    </jet-confirmation-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import JetActionMessage from '@/Jetstream/ActionMessage.vue';
import JetActionSection from '@/Jetstream/ActionSection.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue';
import JetDangerButton from '@/Jetstream/DangerButton.vue';
import JetDialogModal from '@/Jetstream/DialogModal.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue';
import JetSectionBorder from '@/Jetstream/SectionBorder.vue';

export default defineComponent({
  components: {
    JetActionMessage,
    JetActionSection,
    JetButton,
    JetConfirmationModal,
    JetDangerButton,
    JetDialogModal,
    JetFormSection,
    JetInput,
    JetCheckbox,
    JetInputError,
    JetLabel,
    JetSecondaryButton,
    JetSectionBorder,
  },
  props: ['tokens', 'availablePermissions', 'defaultPermissions'],
  data() {
    return {
      createApiTokenForm: this.$inertia.form({
        name: '',
        permissions: this.defaultPermissions,
      }),
      updateApiTokenForm: this.$inertia.form({
        permissions: [],
      }),
      deleteApiTokenForm: this.$inertia.form(),
      displayingToken: false,
      managingPermissionsFor: null,
      apiTokenBeingDeleted: null,
    };
  },
  methods: {
    createApiToken() {
      this.createApiTokenForm.post(route('api-tokens.store'), {
        preserveScroll: true,
        onSuccess: () => {
          this.displayingToken = true;
          this.createApiTokenForm.reset();
        },
      });
    },
    manageApiTokenPermissions(token) {
      this.updateApiTokenForm.permissions = token.abilities;
      this.managingPermissionsFor = token;
    },
    updateApiToken() {
      this.updateApiTokenForm.put(
        route('api-tokens.update', this.managingPermissionsFor),
        {
          preserveScroll: true,
          preserveState: true,
          onSuccess: () => (this.managingPermissionsFor = null),
        }
      );
    },
    confirmApiTokenDeletion(token) {
      this.apiTokenBeingDeleted = token;
    },
    deleteApiToken() {
      this.deleteApiTokenForm.delete(
        route('api-tokens.destroy', this.apiTokenBeingDeleted),
        {
          preserveScroll: true,
          preserveState: true,
          onSuccess: () => (this.apiTokenBeingDeleted = null),
        }
      );
    },
  },
});
</script>
