<template>
  <div v-if="items" class="flex gap-[20px] items-center">
    <div v-for="item in items">
      <a
        :href="item.link"
        target="_blank"
        class="cursor-pointer"
        >{{ item.name }}</a
      >
    </div>
  </div>
  <div v-else></div>
</template>

<script setup>
const props = defineProps({
  items: Object,
});
</script>
