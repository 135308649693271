<template>
  <label
    :for="name"
    class="cursor-pointer block hover:bg-slate-900/3 hover:outline-black/10 hover:outline hover:outline-offset-8 hover:outline-1 rounded-sm"
  >
    <span class="block font-medium text-sm text-slate-600">
      {{ label }}
    </span>
    <div class="mt-2 flex items-center">
      <jet-checkbox
        :value="value"
        :name="name"
        :checked="value"
        @update:checked="update"
      />
      <span class="ml-2 text-sm text-slate-600">{{ actionText }}</span>
    </div>
  </label>
</template>
<script setup>
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import JetLabel from '@/Jetstream/Label.vue';
import { onMounted, ref } from 'vue';

const emit = defineEmits(['update']);
const props = defineProps({
  label: String,
  actionText: {
    type: String,
    default: 'Да',
  },
  value: Number,
});
const update = function (value) {
  emit('update', value);
};
const name = ref(null);
const getRandomString = function (length) {
  let chars =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
  let charLength = chars.length;
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charLength));
  }
  return result;
};
onMounted(() => {
  name.value = getRandomString(12);
});
</script>
