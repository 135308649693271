<template>
  <app-layout :title="title">
    <template #header>
      <div class="flex justify-between">
        <h2 class="text-2xl" v-html="title"></h2>
      </div>
    </template>

    <div class="mx-auto py-4">
      <div class="flow-root">
        <div class="overflow-x-auto">
          <div class="inline-block min-w-full align-middle">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0"
                  >
                    <span class="sr-only">Edit</span>
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900"
                  >
                    Image
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-slate-900 sm:pl-0"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900"
                  >
                    Global type
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900"
                  >
                    Media
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900"
                  >
                    Link
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-right text-sm font-semibold text-slate-900"
                  ></th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="item in modelList.data" :key="item.id">
                  <td
                    class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-slate-900 sm:pl-0 w-[60px]"
                  >
                    <a
                      :href="route(`${prefixRoute}.show`, item.id)"
                      class="bg-slate-50 text-slate-500 inline-flex hover:bg-slate-200 hover:text-slate-800 items-center justify-end p-3 rounded"
                    >
                      <PencilIcon class="h-5 w-5" aria-hidden="true" />
                    </a>
                  </td>
                  <td class="px-2 py-2 whitespace-nowrap">
                    <img
                      v-if="item.image"
                      :src="`/${item.image}`"
                      class="w-[130px] h-[70px] object-contain border rounded-[4px]"
                      alt=""
                    />
                  </td>
                  <td
                    class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-slate-900 sm:pl-0"
                  >
                    <div
                      class="max-w-[150px] truncate overflow-hidden"
                      :title="item.full_name"
                    >
                      {{ item.full_name }}
                    </div>
                  </td>
                  <td
                    class="whitespace-nowrap px-2 py-2 text-sm text-slate-600 font-normal"
                  >
                    {{ item.global_type.name }}
                  </td>
                  <td
                    class="whitespace-nowrap px-2 py-2 text-sm text-slate-600 font-normal"
                  >
                    {{ item.media }}
                  </td>
                  <td
                    class="whitespace-nowrap px-2 py-2 text-sm text-slate-600 font-normal"
                  >
                    <a
                      v-if="item.link"
                      target="_blank"
                      class="underline hover:text-blue-600"
                      :href="item.link"
                      >Figma</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script setup>
import AppLayout from '@/Layouts/AppLayout.vue';
import { PencilIcon } from '@heroicons/vue/outline';

const props = defineProps({
  modelList: Object,
  prefixRoute: String,
  title: String,
});
</script>
