<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="fixed inset-0 flex z-40 md:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-slate-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div
            class="relative flex-1 flex flex-col max-w-xs w-full bg-slate-800"
          >
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <nav class="mt-5 px-2 space-y-1">
                <a
                  v-for="item in navigation"
                  :key="item.name"
                  :href="item.href"
                  :class="[
                    item.current
                      ? 'bg-slate-900 text-white'
                      : 'text-slate-100 hover:bg-slate-700 hover:text-white',
                    'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                  ]"
                >
                  <component
                    :is="item.icon"
                    :class="[
                      item.current
                        ? 'text-slate'
                        : 'text-slate-400 group-hover:text-slate',
                      'mr-4 flex-shrink-0 h-6 w-6',
                    ]"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </a>
              </nav>
            </div>
            <div class="flex-shrink-0 flex bg-slate-700 p-4">
              <a href="#" class="flex-shrink-0 group block">
                <div class="flex items-center">
                  <div>
                    <a
                      :href="route('profile.show')"
                      class="inline-block w-full text-base font-medium text-white"
                      >{{ $page.props.user.name }}</a
                    >
                    <a
                      href="/logout"
                      class="inline-block w-full font-medium text-slate-400 group-hover:text-slate-600"
                      >Logout</a
                    >
                  </div>
                </div>
              </a>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14"></div>
      </Dialog>
    </TransitionRoot>

    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <div class="flex-1 flex flex-col min-h-0 bg-slate-50">
        <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          <div class="flex flex-shrink-0 items-center justify-between px-4">
            <a href="/" class="text-lg font-medium">
              Shuka
              <!-- <img
                class="-left-[11px] relative w-[80px] opacity-50 hover:opacity-100"
                src="/images/admin/logo.png"
                alt="logo"
              /> -->
            </a>

            <a target="_blank" href="https://shuka.design">website</a>
          </div>

          <nav class="mt-3 flex-1 px-2 space-y-1">
            <a
              v-for="item in navigation"
              :key="item.name"
              :href="item.href"
              :target="item.target ? item.target : '_self'"
              :class="[
                route().current(item.route) && item.route
                  ? 'bg-slate-200 text-slate'
                  : 'hover:bg-slate-100 hover:text-slate',
                'group flex items-center px-2 py-2 text-base font-medium rounded-md',
              ]"
            >
              <component
                :is="item.icon"
                :class="[
                  route().current(item.route) && item.route
                    ? 'text-slate'
                    : 'text-slate-400 group-hover:text-slate',
                  'mr-3 flex-shrink-0 h-6 w-6',
                ]"
                aria-hidden="true"
              />
              {{ item.name }}

              <span
                v-if="item.badge"
                class="inline-flex items-center ml-2 px-2.5 py-0.5 rounded-full text-xs font-medium bg-orange-100 text-orange-800"
              >
                <svg
                  class="-ml-0.5 mr-1.5 h-2 w-2 text-orange-400"
                  fill="currentColor"
                  viewBox="0 0 8 8"
                >
                  <circle cx="4" cy="4" r="3" />
                </svg>
                {{ item.badge.value }}
              </span>
            </a>
          </nav>
        </div>
      </div>
    </div>
    <div class="md:pl-64 flex flex-col flex-1">
      <div
        class="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-slate-100"
      >
        <button
          type="button"
          class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-slate-600 hover:text-slate-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <MenuIcon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      <main :class="mainClasses">
        <div class="pt-6 sm:pb-6 md:pb-8">
          <div class="mx-auto px-4 sm:px-6 md:px-8" v-if="$slots.header">
            <slot name="header"></slot>
          </div>
          <div class="mx-auto px-4 sm:px-6 md:px-8">
            <slot></slot>

            <div class="flex-shrink-0 flex mt-[500px]">
              <a href="#" class="flex-shrink-0 w-full group block">
                <div class="flex items-center">
                  <div>
                    <img
                      v-if="$page.props.user.profile_photo_url"
                      :src="$page.props.user.profile_photo_url"
                      alt=""
                      class="inline-block h-9 w-9 rounded-full object-cover"
                      onerror="this.style.display='none'; this.nextElementSibling.style.display='block';"
                    />
                    <div
                      v-else
                      class="inline-block h-9 w-9 rounded-full bg-slate-200"
                    ></div>
                  </div>
                  <div>
                    <a
                      :href="route('profile.show')"
                      class="inline-block w-full font-medium text-slate-600"
                      >{{ $page.props.user.name }}</a
                    >
                    <a
                      href="/logout"
                      class="inline-block w-full font-bold text-red-400 hover:text-red-500"
                      >Logout</a
                    >
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { Link } from '@inertiajs/inertia-vue3';
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  MenuIcon,
  XIcon,
  PuzzleIcon,
  BookmarkIcon,
  UserGroupIcon,
  SpeakerphoneIcon,
  StarIcon,
  ScaleIcon,
  TemplateIcon,
  ExternalLinkIcon,
  LightningBoltIcon,
  ChatAltIcon,
  AdjustmentsIcon,
  ViewBoardsIcon,
  RssIcon,
  HeartIcon,
  PaperAirplaneIcon,
  UserAddIcon,
  UserIcon
} from '@heroicons/vue/outline';

const sidebarOpen = ref(false);

const navigation = [
  {
    name: 'Works',
    href: route('admin::works.index'),
    route: 'admin::works.index',
    icon: PuzzleIcon,
    current: false,
  },
  {
    name: 'Tags',
    href: route('admin::tags.index'),
    route: 'admin::tags.index',
    icon: LightningBoltIcon,
    current: false,
  },
  {
    name: 'Presets',
    href: route('admin::work-item-presets.index'),
    route: 'admin::work-item-presets.index',
    icon: BookmarkIcon,
    current: false,
  },
  {
    name: 'Bundles',
    href: route('admin::bundles.index'),
    route: 'admin::bundles.index',
    icon: ViewBoardsIcon,
    current: false,
  },
  {
    name: 'Teams',
    href: route('admin::team-members.index'),
    route: 'admin::team-members.index',
    icon: UserGroupIcon,
    current: false,
  },
  {
    name: 'Publications',
    href: route('admin::publications.index'),
    route: 'admin::publications.index',
    icon: SpeakerphoneIcon,
    current: false,
  },
  {
    name: 'Newsletters',
    href: route('admin::newsletters.index'),
    route: 'admin::newsletters.index',
    icon: ChatAltIcon,
    current: false,
  },
  {
    name: 'News',
    href: route('admin::news.index'),
    route: 'admin::news.index',
    icon: RssIcon,
    current: false,
  },
  {
    name: 'Events',
    href: route('admin::events.index'),
    route: 'admin::events.index',
    icon: StarIcon,
    current: false,
  },
  {
    name: 'Awards',
    href: route('admin::awards.index'),
    route: 'admin::awards.index',
    icon: ScaleIcon,
    current: false,
  },
  {
    name: 'Blocks',
    href: route('admin::blocks.index'),
    route: 'admin::blocks.index',
    icon: TemplateIcon,
    current: false,
  },
  {
    name: 'Settings',
    href: route('admin::settings.index'),
    route: 'admin::settings.index',
    icon: AdjustmentsIcon,
    current: false,
  },
  {
    name: 'Pages',
    href: route('admin::pages.index'),
    route: 'admin::pages.index',
    icon: PaperAirplaneIcon,
    current: false,
  },
  {
    name: 'Press kit',
    href: route('admin::press-kit-item.index'),
    route: 'admin::press-kit-item.index',
    icon: HeartIcon,
    current: false,
  },
  {
    name: 'Vacancy',
    href: route('admin::vacancy-item.index'),
    route: 'admin::vacancy-item.index',
    icon: UserAddIcon,
    current: false,
  },
  {
    name: 'Users',
    href: route('admin::users.index'),
    route: 'admin::users.index',
    icon: UserIcon,
    current: false,
  },
  {
    name: 'Typology Figma',
    href: 'https://www.figma.com/file/SDAqMglAui4kjwGSuLTKwq/Shuka-%C2%B7-Cases?node-id=596%3A5583',
    route: '',
    icon: ExternalLinkIcon,
    current: false,
    target: '_blank',
  },
];

const mainClasses = computed(() => ({
  'flex-1': true,
}));

const hasAnyPermission = (permissions) => {
  const allPermissions = $page.props.auth.can;
  return permissions.some((permission) => allPermissions[permission]);
};

const logout = () => {
  $inertia.post(route('logout'));
};
</script>
