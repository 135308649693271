<template>
  <Head title="Email Verification" />

  <jet-authentication-card>
    <template #logo>
      <jet-authentication-card-logo />
    </template>

    <div class="mb-4 text-sm text-slate-600">
      <!--			Thanks for signing up! Before getting started, could you verify your email address by clicking on the link we just-->
      <!--			emailed to you? If you didn't receive the email, we will gladly send you another.-->
      Спасибо за регистрацию! Прежде чем начать, не могли бы вы подтвердить
      адрес электронной почты, нажав на ссылку, которую мы только что отправили
      вам по электронной почте? Если вы не получили письмо, мы с радостью
      отправим вам другое.
    </div>

    <div
      class="mb-4 font-medium text-sm text-green-600"
      v-if="verificationLinkSent"
    >
      <!--			A new verification link has been sent to the email address you provided during registration.-->
      На адрес электронной почты, который вы указали при регистрации, была
      отправлена новая ссылка для подтверждения.
    </div>

    <form @submit.prevent="submit">
      <div class="mt-4 flex items-center justify-between">
        <jet-button
          :class="{ 'opacity-25': form.processing }"
          :disabled="form.processing"
        >
          <!--					Resend Verification Email-->
          Выслать повторно письмо для подтверждения
        </jet-button>

        <Link
          :href="route('logout')"
          method="post"
          as="button"
          class="underline text-sm text-slate-600 hover:text-slate-900"
          >Выйти
        </Link>
      </div>
    </form>
  </jet-authentication-card>
</template>

<script>
import { defineComponent } from 'vue';
import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue';
import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue';
import JetButton from '@/Jetstream/Button.vue';
import { Head, Link } from '@inertiajs/inertia-vue3';

export default defineComponent({
  components: {
    Head,
    JetAuthenticationCard,
    JetAuthenticationCardLogo,
    JetButton,
    Link,
  },
  props: {
    status: String,
  },
  data() {
    return {
      form: this.$inertia.form(),
    };
  },
  methods: {
    submit() {
      this.form.post(this.route('verification.send'));
    },
  },
  computed: {
    verificationLinkSent() {
      return this.status === 'verification-link-sent';
    },
  },
});
</script>
