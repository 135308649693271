<template>
  <input
    :class="{
      'border border-slate-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm': true,

      'px-4 py-2 outline-none': !color,
    }"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    ref="input"
  />
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: ['modelValue', 'color'],
  emits: ['update:modelValue'],
  methods: {
    focus() {
      this.$refs.input.focus();
    },
  },
});
</script>
