<template>
  <div
    class="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-slate-100"
  >
    <div>
      <slot name="logo" />
    </div>
    <div
      class="w-full sm:max-w-md mt-12 px-6 py-6 bg-white shadow-lg overflow-hidden sm:rounded-lg"
    >
      <slot />
    </div>
  </div>
</template>
