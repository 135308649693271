<template>
  <app-layout :title="title">
    <template #header>
      <div class="flex justify-between">
        <h2 class="text-2xl" v-html="title"></h2>
      </div>
    </template>

    <div class="mx-auto py-4">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="mt-5 md:mt-0 md:col-span-2">
          <form @submit.prevent="submit">
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6 sm:col-span-4">
                <jet-label for="name" value="Name" />
                <jet-input
                  id="name"
                  type="text"
                  class="block w-full mt-1"
                  v-model="form.name"
                  autofocus
                />
                <jet-input-error :message="form.errors.name" class="mt-2" />
              </div>
              <div class="col-span-6 sm:col-span-4">
                <jet-label for="email" value="Email" />
                <jet-input
                  id="name"
                  type="text"
                  class="block w-full mt-1"
                  v-model="form.email"
                  autofocus
                />
                <jet-input-error :message="form.errors.email" class="mt-2" />
              </div>
            </div>
            <div class="flex items-center justify-start pt-8">
              <jet-button
                :class="{ 'opacity-25': form.processing }"
                :disabled="form.processing"
              >
                Create
              </jet-button>
              <jet-action-message :on="form.recentlySuccessful" class="ml-3">
                Created.
              </jet-action-message>
            </div>
          </form>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script setup>
import { ref } from 'vue';
import { useForm } from '@inertiajs/inertia-vue3';
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetActionMessage from '@/Jetstream/ActionMessage';

const props = defineProps({
  model: Object,
  prefixRoute: String,
  title: String,
  titleSection: String,
  descSection: String,
});

const form = useForm({
  name: null,
});

function submit() {
  form.post(route(`${props.prefixRoute}.store`));
}
</script>
