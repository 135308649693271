<template>
  <Head title="Log in" />

  <jet-authentication-card>
    <template #logo>
      <jet-authentication-card-logo />
    </template>

    <div class="sm:max-w-md w-full">
      <a
        href="/auth-google-redirect"
        class="block active:bg-slate-900 bg-blue-600 border border-transparent cursor-pointer disabled:opacity-25 focus:border-slate-900 focus:outline-none focus:ring focus:ring-slate-300 font-light hover:bg-slate-700 items-center mb-4 px-4 py-2 py-4 rounded-md text-center text-white text-base tracking-widest transition w-full"
      >
        Вход через Google
      </a>

      <!--
			<div @click="authAppleID" class="block active:bg-slate-900 bg-slate-900 border border-transparent cursor-pointer disabled:opacity-25 focus:border-slate-900 focus:outline-none focus:ring focus:ring-slate-300  hover:bg-slate-700 items-center px-4 py-2 py-4 rounded-md text-center text-white text-xs tracking-widest transition  w-full">
				Вход через Apple ID
			</div>
			-->

      <div class="mt-6 text-center">или</div>
    </div>

    <jet-validation-errors class="mb-4" />

    <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
      {{ status }}
    </div>

    <form @submit.prevent="submit" ref="formAuth">
      <div v-if="!auth_code">
        <jet-label for="email" value="Email" />
        <jet-input
          id="email"
          type="email"
          class="mt-1 block w-full"
          v-model="form.email"
          required
        />
      </div>

      <div class="mt-4" v-if="auth_code">
        <jet-label for="password" value="Password" />
        <jet-input
          id="password"
          type="password"
          class="mt-1 block w-full"
          v-model="form.password"
          required
        />
      </div>

      <div class="flex items-center justify-between mt-4">
        <div class="block" v-if="!isStorageEmail">
          <label class="flex items-center">
            <jet-checkbox name="remember" v-model:checked="remember" />
            <span class="ml-2 text-sm text-slate-600">Запомнить меня</span>
          </label>
        </div>

        <div class="cursor-pointer text-sm" v-if="isStorageEmail">
          <div
            class="inline-block hover:text-blue-900 text-slate-400"
            @click="useStorageEmail"
          >
            {{ storageEmail }}
          </div>
          <div
            class="hover:text-red-600 inline-block ml-2 text-red-600"
            @click="removeStorageEmail"
          >
            x
          </div>
        </div>

        <jet-button
          class="ml-4"
          :class="{ 'opacity-25': form.processing }"
          :disabled="form.processing"
        >
          Войти
        </jet-button>
      </div>
    </form>
  </jet-authentication-card>
</template>

<script>
import { defineComponent, ref, onMounted, onUpdated } from 'vue';
import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue';
import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetValidationErrors from '@/Jetstream/ValidationErrors.vue';
import { Head, Link } from '@inertiajs/inertia-vue3';

export default defineComponent({
  components: {
    Head,
    JetAuthenticationCard,
    JetAuthenticationCardLogo,
    JetButton,
    JetInput,
    JetCheckbox,
    JetLabel,
    JetValidationErrors,
    Link,
  },
  props: {
    canResetPassword: Boolean,
    status: String,
    telegram_code: String,
    auth_code: Boolean,
  },
  setup() {
    const formAuth = ref(null);
    onMounted(() => {
      console.log('formAuth.value', formAuth.value);
    });
    return {
      formAuth,
    };
  },
  data() {
    return {
      remember: false,
      form: this.$inertia.form({
        email: '',
        password: '',
        remember: false,
      }),
      get storageEmail() {
        return localStorage.getItem('ac_email') || '';
      },
      set storageEmail(value) {
        console.log('storageEmail', value);
        if (value === null) {
          localStorage.removeItem('ac_email');
        } else {
          localStorage.setItem('ac_email', value);
        }
      },
    };
  },
  methods: {
    authAppleID() {
      alert('Скоро! Но не сейчас.');
    },
    submit() {
      let self = this;
      console.log('submit');
      console.log(typeof self.auth_code, self.auth_code);
      if (!self.auth_code) {
        this.form
          .transform((data) => ({
            ...data,
            remember: this.form.remember ? 'on' : '',
          }))
          .post(this.route('login'), {
            onFinish: () => {
              console.log(this.form.email);
              if (self.remember) {
                self.setStorageEmail(this.form.email);
              }
              // self.form.email = ''
            },
          });
      } else {
        this.form
          .transform((data) => ({
            ...data,
            remember: this.form.remember ? 'on' : '',
          }))
          .post(this.route('login-code'), {
            onFinish: (dd) => {
              // route('home-page');
            },
            onSuccess: (page) => {
              console.log(page);
              route('home-page');
            },
            onError: (errors) => {
              console.warn(errors);
            },
          });
      }
    },
    removeStorageEmail() {
      this.storageEmail = null;
    },
    setStorageEmail(email) {
      this.storageEmail = email;
    },
    useStorageEmail() {
      let self = this;
      this.form.email = this.storageEmail;
      setTimeout(() => {
        self.submit();
      }, 500);
    },
  },
  computed: {
    isStorageEmail() {
      return this.storageEmail !== '';
    },
  },
});
</script>
