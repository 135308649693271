<template>
  <app-layout :title="title">
    <template #header>
      <div class="flex justify-between">
        <h2 class="text-2xl" v-html="title"></h2>
        <div class="flex gap-[40px] justify-between">
          <app-useful-links :items="usefulLinks" />
          <a :href="route(`${prefixRoute}.create`)">
            <jet-button>Create</jet-button>
          </a>
        </div>
      </div>
    </template>

    <div class="mx-auto py-4">
      <div class="flow-root">
        <div class="overflow-x-auto">
          <div class="inline-block min-w-full align-middle">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0"
                  >
                    <span class="sr-only">Edit</span>
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-slate-900 sm:pl-0"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900"
                  >
                    URL
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="item in modelList.data" :key="item.id">
                  <td
                    class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-slate-900 sm:pl-0 w-[60px]"
                  >
                    <a
                      :href="route(`${prefixRoute}.show`, item.id)"
                      class="bg-slate-50 text-slate-500 inline-flex hover:bg-slate-200 hover:text-slate-800 items-center justify-end p-3 rounded"
                    >
                      <PencilIcon class="h-5 w-5" aria-hidden="true" />
                    </a>
                  </td>
                  <td
                    class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-slate-900 sm:pl-0"
                  >
                    <div
                      class="max-w-[150px] truncate overflow-hidden"
                      :title="item.name"
                    >
                      {{ item.name }}
                    </div>
                  </td>
                  <td
                    class="whitespace-nowrap px-2 py-2 text-sm text-slate-500"
                  >
                    <div
                      class="max-w-[250px] truncate overflow-hidden"
                      :title="item.desc"
                    >
                      {{ item.desc }}
                    </div>
                  </td>
                  <td
                    class="whitespace-nowrap px-2 py-2 text-sm text-slate-900"
                  >
                    <div class="max-w-[200px] truncate overflow-hidden">
                      <a
                        v-if="item.url"
                        class="text-sky-600 hover:text-sky-900"
                        :href="item.url"
                        :title="item.url"
                        >{{ getDomain(item.url) }}</a
                      >
                      <span v-else>-</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <app-sort-card :items="sortItems" :path="sortItemsURL" />
  </app-layout>
</template>

<script setup>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import AppSortCard from '@/Components/Sort/SortCard.vue';
import AppUsefulLinks from '@/Components/usefulLinks.vue';
import PencilIcon from '@heroicons/vue/outline/PencilIcon';

const props = defineProps({
  modelList: Object,
  sortItems: Object,
  sortItemsURL: String,
  prefixRoute: String,
  title: String,
  usefulLinks: Object,
});

function getDomain(url) {
  if (!url) {
    return '-';
  }
  try {
    const newUrl = new URL(url);
    return newUrl.hostname.replace('www.', '');
  } catch (e) {
    return url;
  }
}
</script>
