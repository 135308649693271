<template>
  <app-layout :title="title">
    <template #header>
      <div class="flex justify-between">
        <h2 class="text-2xl">{{ title }}</h2>
        <div></div>
      </div>
    </template>

    <div class="mx-auto py-4">Редактор главной</div>
  </app-layout>
</template>

<script setup>
import { defineComponent } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';

const title = 'Editor main page';
</script>
