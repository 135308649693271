<template>
  <app-layout title="Dashboard">
    <template #header>
      <h2 class="text-2xl">Dashboard</h2>
    </template>

    <div class="py-8">
      <div class="mx-auto">
        <welcome />
      </div>
    </div>
  </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import Welcome from '@/Jetstream/Welcome.vue';

export default defineComponent({
  components: {
    AppLayout,
    Welcome,
  },
});
</script>
